<template>
    <div>
      <!-- media -->
      <b-media no-body>
        <b-media-aside>
          <b-link>
            <b-avatar
              ref="previewEl"
              :src="profileData.info.avatar || '/img/avatar-s-11.14cf1734.jpg'"
              :text="profileData.fullName"
              size="90px"
              rounded
            />
          </b-link>
        </b-media-aside>
  
        <b-media-body class="mt-75 ml-75">
          <b-button
            variant="primary"
            size="sm"
            class="mb-75 mr-75"
            @click="$refs.refInputEl.click()"
          >
            <input
              ref="refInputEl"
              type="file"
              class="d-none"
              @input="inputImageRenderer"
            >
            <span class="d-none d-sm-inline">Update</span>
            <feather-icon
              icon="EditIcon"
              class="d-inline d-sm-none"
            />
          </b-button>
          
          <!-- reset -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            size="sm"
            class="mb-75 mr-75"
            @click.prevent="resetAvatar"
          >
            Reset
          </b-button>
          <!--/ reset -->
          <b-card-text>Allowed JPG. Max size of 500kB</b-card-text>
        </b-media-body>
      </b-media>
      <!--/ media -->
  
      <!-- form -->
      <b-form class="mt-2">
        <b-row>
          <b-col sm="6">
            <b-form-group
              label="First Name"
              label-for="first-name"
            >
              <b-form-input
                v-model="profileData.info.firstname"
                placeholder="first Name"
                name="firstname"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Last Name"
              label-for="last-name"
            >
              <b-form-input
                v-model="profileData.info.lastname"
                name="lastname"
                placeholder="Last Name"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="E-mail"
              label-for="account-e-mail"
            >
              <b-form-input
                v-model="profileData.email"
                name="email"
                placeholder="Email"
              />
  
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Company"
              label-for="account-company"
            >
              <b-form-input
                v-model="profileData.info.company"
                name="company"
                placeholder="Company name"
              />
            </b-form-group>
          </b-col>
  
          <!-- alert -->
          <b-col
            cols="12"
            class="mt-75"
          >
            <b-alert
              show
              variant="warning"
              class="mb-50"
            >
            </b-alert>
          </b-col>
          <!--/ alert -->
  
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
            >
              Save changes
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              type="reset"
              class="mt-2"
              @click.prevent="resetForm"
            >
              Reset
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </div>
  </template>
  
  <script>
  import {
    BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink,BAvatar
  } from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'
  import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
  import { ref } from '@vue/composition-api'
  
  export default {
    components: {
      BButton,
      BForm,
      BAvatar,
      BFormGroup,
      BFormInput,
      BRow,
      BCol,
      BAlert,
      BCard,
      BCardText,
      BMedia,
      BMediaAside,
      BMediaBody,
      BLink,
    },
    directives: {
      Ripple,
    },
    methods: {
      resetForm() {
        this.profileData = JSON.parse(localStorage.getItem('userData'))
      },
      resetAvatar() {
        this.profileData.info.avatar = JSON.parse(localStorage.getItem('userData')).info.avatar;
      }
    },
    props: {
      profileData: {
        type: Object,
        required: true,
      },
    },
    setup(props) {
      const refInputEl = ref(null)
      const previewEl = ref(null)
  
      const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
        props.profileData.info.avatar = base64
      });
       
      return {
        refInputEl,
        previewEl,
        inputImageRenderer
      }
    },
  }
  </script>
  