<template>
      <profile-gen-form
        :profile-data="profileData"
        class="mt-2 pt-75"
      />
</template>

<script>
import ProfileGenForm from './ProfileGenForm.vue'
export default {
  components: {
    ProfileGenForm,
  },
  data() {
    return{
      profileData : JSON.parse(localStorage.getItem('userData'))
    }
  },
}
</script>